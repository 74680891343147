<template>
    <room-floor-editor-component/>
</template>

<script>
    import RoomFloorEditorComponent from "../../../../components/settings/cashier/RoomFloor/RoomFloorEditorComponent";
    export default {
        name: "RoomFloorEditor",
        components:{
            RoomFloorEditorComponent
        }
    }
</script>

<style scoped>

</style>